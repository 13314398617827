import {useEffect, useState} from 'react'
import './shadow.css'





function Shadow (props) {

    return( <>
    <div class="hero">
        <div class="hero-shadow "></div>
        <div class="hero-text ">
            <h3>Portret nie powstaje w aparacie ale po obu jego stronach</h3>
           
        </div>
        </div>
    
    
    </>)
}

export default Shadow