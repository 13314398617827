import {useEffect} from 'react'
import './outdoors.css'


import P2 from '../img/plener/P2.jpg'
import P3 from '../img/plener/P3.jpg'
import P4 from '../img/plener/P4.jpg'
import P5 from '../img/plener/P5.jpg'
import P6 from '../img/plener/P6.jpg'
import P7 from '../img/plener/P7.jpg'
import P8 from '../img/plener/P8.jpg'
import P9 from '../img/plener/P9.jpg'
import P10 from '../img/plener/P10.jpg'
import P11 from '../img/plener/P11.jpg'
import P12 from '../img/plener/P12.jpg'
import P14 from '../img/plener/P14.jpg'
import P15 from '../img/plener/P15.jpg'
import P16 from '../img/plener/P16.jpg'
import P17 from '../img/plener/P17.jpg'
import P18 from '../img/plener/P18.jpg'
import P19 from '../img/plener/P19.jpg'
import P20 from '../img/plener/P20.jpg'
import P21 from '../img/plener/P21.jpg'
import P23 from '../img/plener/P23.jpg'
import P24 from '../img/plener/P24.jpg'
import P25 from '../img/plener/P25.jpg'
import P26 from '../img/plener/P26.jpg'
import P27 from '../img/plener/P27.jpg'
import P28 from '../img/plener/P28.jpg'
import P29 from '../img/plener/P29.jpg'
import P30 from '../img/plener/P30.jpg'
import P31 from '../img/plener/P31.jpg'
import P32 from '../img/plener/P32.jpg'
import P33 from '../img/plener/P33.jpg'
import P34 from '../img/plener/P34.jpg'
import P35 from '../img/plener/P35.jpg'
import P36 from '../img/plener/P36.jpg'
import P37 from '../img/plener/P37.jpg'
import P38 from '../img/plener/P38.jpg'
import P39 from '../img/plener/P39.jpg'
import P40 from '../img/plener/P40.jpg'
import P41 from '../img/plener/P41.jpg'
import P42 from '../img/plener/P42.jpg'
import P43 from '../img/plener/P43.jpg'
import P44 from '../img/plener/P44.jpg'
import P45 from '../img/plener/P45.jpg'
import P46 from '../img/plener/P46.jpg'
import P47 from '../img/plener/P47.jpg'
import P48 from '../img/plener/P48.jpg'
import P49 from '../img/plener/P49.jpg'
import P50 from '../img/plener/P50.jpg'
import P51 from '../img/plener/P51.jpg'
import P52 from '../img/plener/P52.jpg'
import P53 from '../img/plener/P53.jpg'
import P54 from '../img/plener/P54.jpg'
import P55 from '../img/plener/P55.jpg'
import P56 from '../img/plener/P56.jpg'
import P57 from '../img/plener/P57.jpg'
import P58 from '../img/plener/P58.jpg'
import P59 from '../img/plener/P59.jpg'
import P60 from '../img/plener/P60.jpg'
import P61 from '../img/plener/P61.jpg'
import P62 from '../img/plener/P62.jpg'
import P63 from '../img/plener/P63.jpg'
import P64 from '../img/plener/P64.jpg'
import P65 from '../img/plener/P65.jpg'
import P66 from '../img/plener/P66.jpg'
import P67 from '../img/plener/P67.jpg'
import P68 from '../img/plener/P68.jpg'


function Outdoors (props) {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      };

      useEffect(() => {
        scrollToTop()},[])
    return(
    <div className='outdoors'>
        <p className='outp'>
        Sesje plenerowe w pięknych krajobrazach i ciekawych miejscach to niepowtarzalne doświadczenie, 
        które przekłada się na wyjątkowe rezultaty fotograficzne. Tego rodzaju sesje oferują szereg zalet, 
        zarówno estetycznych, jak i emocjonalnych, które sprawiają, że każda chwila staje się niezapomniana.
        Po pierwsze, naturalne piękno otaczającego krajobrazu staje się doskonałym tłem dla sesji plenerowej. 
        Zastosowanie różnorodnych scenerii, od malowniczych plaż po zielone lasy czy miejskie zakątki, pozwala 
        na uzyskanie różnorodnych i fascynujących ujęć, dodając unikalny charakter każdej fotografii.</p>
        
        
        
<div className='textout'>
<img src={P6} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P7} className='outimg' alt='chłopiec na plaży'></img>
<img src={P8} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P9} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P10} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P11} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P12} className='outimg' alt='sesja plenerowa fotografa'></img>
<div className='insideC'><img src={P2} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P3} className='outimg' alt='spacer ojca i syna na plaży'></img></div>
<div className='insideC'><img src={P4} alt='zdjęcie chłopca na tle zieleni' className='outimg'></img>
<img src={P5} className='outimg'    alt='zdjęcie kwiatów' ></img></div>
<img src={P14} alt='chłopiec robi fikołka na plaży' className='outimg'></img>
<img src={P15} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P16} className='outimg' alt='chłopiec na plaży'></img>
<img src={P17} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P18} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P19} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P20} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P21} className='outimg' alt='sesja plenerowa fotografa'></img>

<img src={P23} alt='chłopiec robi fikołka na plaży' className='outimg'></img>
<img src={P24} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P25} className='outimg' alt='chłopiec na plaży'></img>
<img src={P26} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P27} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P28} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P29} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P30} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P31} className='outimg'    alt='zdjęcie kwiatów' ></img>
<img src={P32} alt='chłopiec robi fikołka na plaży' className='outimg'></img>
<img src={P33} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P34} className='outimg' alt='chłopiec na plaży'></img>
<img src={P35} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P36} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P37} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P38} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P39} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P40} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P41} className='outimg' alt='chłopiec na plaży'></img>
<img src={P42} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P43} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P44} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P45} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P46} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P47} className='outimg'    alt='zdjęcie kwiatów' ></img>
<img src={P48} alt='chłopiec robi fikołka na plaży' className='outimg'></img>
<img src={P49} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P50} className='outimg' alt='chłopiec na plaży'></img>
<img src={P51} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P52} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P53} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P54} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P55} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P56} className='outimg'    alt='zdjęcie kwiatów' ></img>
<img src={P57} alt='chłopiec robi fikołka na plaży' className='outimg'></img>
<img src={P58} className='outimg'     alt='zdjęcie oceanu' ></img>
<img src={P59} className='outimg' alt='chłopiec na plaży'></img>
<img src={P60} className='outimg' alt='zdjęcie nad oceanem'></img>
<img src={P61} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P62} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P63} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P64} className='outimg' alt='sesja plenerowa fotografa'></img>
<img src={P65} className='outimg'     alt='spacer ojca i syna na plaży' ></img>
<img src={P66} className='outimg' alt='chłopiec ogląda mural'></img>
<img src={P67} alt='zdjęcia pamiątek z wakacji' className='outimg'></img>
<img src={P68} className='outimg' alt='sesja plenerowa fotografa'></img>
    
  </div> <div/></div>)
}

export default Outdoors